*, body {
    margin: 0;
    padding : 0;
}

@font-face { 
    font-family: 'Noto Sans';
    src: url('../../assets/fonts/NotoSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap; 
} 

@font-face {
    font-family: 'Noto Sans';
    src: url('../../assets/fonts/NotoSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;   
    font-display: swap;  
}      
  
@font-face {
    font-family: 'Noto Sans';
    src: url('../../assets/fonts/NotoSans-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;   
    font-display: swap;  
}  
  
 